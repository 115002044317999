export const BASE_API = 'https://icard.juanitodev.com';
export const TOKEN = 'token';

export const ORDER_STATUS = {
    PENDING: 'PENDING',
    DELIVERED: 'DELIVERED'
}

export const PAYMENT_STATUS = {
    PENDING: 'PENDING',
    PAID: 'PAID'
}